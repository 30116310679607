
import request from '@/utils/request'

export function getData (pageType,data) {
  return request({
    url: '/pay/' + pageType,
    method: 'POST',
    data
  })
}
export function deviceLogin (data) {
  return request({
    url: '/deviceLogin',
    method: 'POST',
    data
  })
}

export function mealCardCheck (data) {
  return request({
    url: '/mealCard/check',
    method: 'GET',
    params:data
  })
}

// 管理员刷卡登录 用于换货
export function exchangeAdminLogin (params) {
  return request({
    url: '/mealCard/exchangeCheck',
    params
  })
}

// 查询用户订单
export function getOrderDetailByNumber (params) {
  return request({
    url: '/exchange/order',
    params
  })
}


