<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    },
    layout:{
      type:Object,
      default:function(){
        return {
          'default': [
            "1 2 3 4 5 6 7 8 9 0",
            "q w e r t y u i o p",
            '{lock} a s d f g h j k l',
            "{bksp} z x c v b n m . {enter}",
          ],
          'shift': [
            '- _ = + @ / * ( ) :',
            'Q W E R T Y U I O P',
            '{lock} A S D F G H J K L',
            '{bksp} Z X C V B N M . {enter}',
          ]
        }
      }
    }
  },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    console.log(this.keyboardClass)
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress
    });
    this.setKeyboard()
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      if (button === "{shift}" || button === "{lock}") this.handleShift();

      this.$emit("onKeyPress", button);
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    },
    setKeyboard() {
      this.keyboard.setOptions({
        layoutName: "default",
        theme: "hg-theme-default hg-layout-default myTheme",
        layout:this.layout,
        buttonTheme: [
          {
            class: "shift",
            buttons: "{shift}"
          },
          {
            class: "lock",
            buttons: "{lock}"
          },
          {
            class: "bksp",
            buttons: "{bksp}"
          },
          {
            class: "enter",
            buttons: "{enter}"
          }
        ],
        display: {
          '{bksp}': "删除",
          '{lock}': "切换",
          '{enter}': "确认"
        }
      });
    }
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);

    }
  }
};
</script>
<style>
.hg-standardBtn,.hg-functionBtn{
  height:2rem !important;
  font-size:0.65rem;
}
</style>
