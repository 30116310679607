<template>
  <div class="app-contanier">
    <div class="logo">
      <van-image width="1rem" height="1rem" fit="contain" :src="require('@/assets/logo.png')" @click="goAdminLogin"/>
      <span>供销门市部</span>
    </div>
    <div class="title">欢迎使用自助结算</div>
    <div class="sub-title">商品条码对准下方扫码口</div>
    <div class="eg-bg">
      <van-image width="7.38rem" height="7.98rem" fit="contain" :src="require('@/assets/bg01.png')" />
      <div class="eg">
        <van-image width="3rem" height="2.44rem" fit="contain" :src="require('@/assets/eg01.png')" />
      </div>
      <div class="line"></div>
    </div>
    <div class="settle-btn">
      <van-button @click="goSettle" round color="linear-gradient(to right, #EA0B12, #D80B11)" block>开始结算</van-button>
       <div style="height: 40px;"></div>
      <van-button @click="goBound" round color="linear-gradient(to right, #FFA508, #FF8C00)" block>饭卡绑定</van-button>
    </div>
    <div class="bottom">张家港市杨舍供销合作社</div>
  </div>
</template>

<script>
import { setToken,getToken } from '@/utils/token'
import {deviceLogin} from '@/api/home';
export default {
  data() {
    return {
      active: 0,
      flag:false
    }
  },
  created() {
    var that = this;
    let str = '';
    document.onkeydown = function (e) {
      var key = window.event.key
      console.log(key,that.flag)
      if (key === 'Enter' && !that.flag) {
        that.goSettle(str)
        str = ''
      } else {
        str = str + key;
      }
    }
  },
  mounted(){
    if(!getToken()){
      this.deviceLogin();
    }
  },
  methods: {
    goAdminLogin() {
      this.$router.push({ path: "/admin/login" });
    },
    goSettle(str) {
      this.$router.push({ name: "settle", params: { 'barcode': str } });
    },
    goBound(){
      this.$router.push({ name: "bindCard" });
    },
    deviceLogin(){
      let params = this.$route.params;
      deviceLogin({
        'username':params.username,
        'password':params.password,
      }).then(res=>{
        setToken(res.data.token);
        this.$storage.set('store',res.data)
        this.$storage.set('login',params)
      })
    },
  }
}
</script>

<style lang="less" scoped>
.app-contanier {
  height: 100vh;
  overflow: hidden;
  // padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 18px;
  span{
    padding-left: 14px;

  }
  // font-weight: bold;
}
.title {
  margin-top: 16px;
  font-size: 30px;
  letter-spacing: 2px;
}

.sub-title {
  margin: 10px 0 23px;
  font-size: 16px;
  letter-spacing: 2px;
}

.eg-bg {
  position: relative;

  .eg {
    position: absolute;
    bottom: 0px;
    right: 30px;

    animation: shaking 1.25s linear infinite;
    -webkit-animation: shaking 1.25s linear infinite;
  }



  .line {
    position: absolute;
    width: 110px;
    height: 3px;
    right: 31px;
    bottom: 75px;
    background-color: #FF0000;
  }
}
.bottom{
  position: fixed;
  right: 15px;
  bottom: 10px;
  font-size: 13px;
}

.settle-btn {
  margin-top: 30px;
  width: 210px;
}

@keyframes shaking {

  20% {
    bottom: 20px;
  }

  40% {
    bottom: 30px;
  }

  60% {
    bottom: 40px;
  }

  80% {
    bottom: 20px;
  }

  100% {
    bottom: 0;
  }
}
</style>
